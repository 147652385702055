body, h1 {
  margin: 0;
  padding: 0;
}

body {
  background-color: #fafafa;
  font-family: BIZ UDPGothic, sans-serif;
}

em {
  background: #c67216;
  font-style: normal;
}

.header {
  color: #fafafa;
  background-image: linear-gradient(to right, #1e2585, #c67216);
  align-items: center;
  min-height: 50px;
  margin-bottom: 1rem;
  padding: .5rem 1rem;
  display: flex;
}

.header a {
  color: #fafafa;
  text-decoration: none;
}

.header-title {
  font-size: 1.5rem;
  font-weight: bold;
}

.header img {
  margin: 0;
}

.header-title:after {
  content: "  ";
  padding: 0 1rem;
}

.header-subtitle {
  margin-right: 1rem;
  padding: auto;
  font-size: 1rem;
  line-height: 1.5rem;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 1rem;
}

.search-panel {
  display: flex;
}

.search-panel__filters {
  flex: 1;
}

.search-panel__results {
  flex: 3;
}

.searchbox {
  margin-bottom: 2rem;
}

.pagination {
  text-align: center;
  margin: 2rem auto;
}

footer {
  text-align: center;
  color: #fafafa;
  background-image: linear-gradient(to right, #1e2585, #c67216);
}

/*# sourceMappingURL=index.651ced92.css.map */
